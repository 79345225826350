import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// Swiper.use(Navigation, Pagination);

window.customConfigs = {
    'single-slide'             : {},
    
    'desktop-three-slide'      : {
        slidesPerView : 1,
        breakpoints   : {
            768 : {
                slidesPerView: 3
            }
        }
    },

    'auto-slides'              : {
        slidesPerView : 'auto',
        spaceBetween  : 30
    },

    'single-slide-auto-scroll' : {
        slidesPerView : 1,
        autoplay      : {
            delay : 5000,
        }
    }
}

const defaultConfig = {
    modules: [
        Navigation,
        Pagination,
        Autoplay
    ],

    pagination: {
        el: ".swiper-pagination"
    }
};

window.siteCarousels = new Map();
document.querySelectorAll('.swiper')
    .forEach(carousel => {
        siteCarousels.set(
            carousel,
            new Swiper(carousel, {
                ...(customConfigs[carousel.dataset.config] ?? {}),

                ...defaultConfig
            })
        );
    });

export default Swiper;