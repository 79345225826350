//If you want cookie consent
// import "./modules/cookie-consent";

//If you need a class to be toggled on a different element
// import "./modules/toggle";

//If you need to do something when its in/out of the viewport
// import "./modules/intersects";

import './modules/carousel';

import './modules/header';

import AOS from 'aos';
AOS.init();